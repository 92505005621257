/* eslint-disable @typescript-eslint/no-var-requires */
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/** *************************************************************************************************
 * BROWSER POLYFILLS
 */

// Internet Explorer 9 support
// import 'ie-shim';

// IE9, IE10 and IE11 requires all of the following polyfills.
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

// Evergreen browsers require these.
// import 'core-js/es6/reflect';
// import 'core-js/es7/reflect';
// import 'core-js/es7/object';

/**
 * Required to support Web Animations `@angular/animation`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 */

import 'webrtc-adapter/out/adapter';
import 'zone.js';
import { polyfill } from 'keyboardevent-key-polyfill';

/** *************************************************************************************************
 * Zone JS is required by Angular itself.
 */
// Required for Spokes to work properly
(window as any).originalSetInterval = window.setInterval;
(window as any).originalSetTimeout = window.setTimeout;
// import 'zone.js/dist/long-stack-trace-zone' // async stack traces with zone.js included for dev

/** *************************************************************************************************
 * APPLICATION IMPORTS
 */

// Key polyfill for Safari browsers
polyfill();

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';
